@font-face {
  font-family: "Cooper-Std-Black";
  src: local("Cooper-Std-Black"),
   url("./fonts/Cooper-Std-Black/Cooper-Std-Black.ttf") format("truetype");
  font-weight: bold;
  }

.navbar {
  background: #3c7090;
  color: #fff;
}
.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-brand {
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-family: "Cooper-Std-Black";
  font-size: 20px;
  text-shadow: none;
}
.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: #8FC4EF;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  
}

.text-right {
  float: right;
}


.form-custom {
  background: #114769;
  border: 0;
  font-family: "Cooper-Std-Black";
  color: #fff;
  text-shadow: none;
  padding: 7px 10px;
  font-size: 16px;
}

.btn-default {
  background: #114769;
  border: 0;
  font-family: "Cooper-Std-Black";
  color: #fff;
  text-shadow: none;
  padding: 7px 10px;
  font-size: 16px;
}

.dropdown-menu .navbar-form .form-control,
.dropdown-menu .navbar-form .btn-default {
 
}
.form-control::-moz-placeholder{color:#000;opacity:1; font-weight: bold;}
.form-control:-ms-input-placeholder{color:#000; font-weight: bold;}
.form-control::-webkit-input-placeholder{color:#000; font-weight: bold;}

h2 {
  color: #ee5a42;
  font-family: "Cooper-Std-Black";
  font-size: 24px;
}
h3 {
  color: #000;
  font-family: "Cooper-Std-Black";
  font-size: 24px;
}

.container .jumbotron,
.container-fluid .jumbotron {
  background: none;
  padding: 10px 0;
}
.container .jumbotron h2,
.container-fluid .jumbotron h2 {
  color: #000;
  font-size: 55px;
}
.container .jumbotron_header h2,
.container-fluid .jumbotron h2 {
  color: #000;
  font-size: 55px;
}
.container .jumbotron h3,
.container-fluid .jumbotron h3 {
  color: #000;
  font-size: 24px;
}
.container .jumbotron_header h3,
.container-fluid .jumbotron h3 {
  color: #000;
  font-size: 24px;
}
.container .jumbotron p,
.container-fluid .jumbotron p {
  font-size: 16px;
  line-height: 25px;
}

.container .jumbotron img.intro-image {
  float: left;
  margin: 0 20px 0 0;
}

.table-bordered {
  margin: 20px 0;
}

@media (min-width:768px){
  .container {
width: auto;
  }
}
@media (min-width:992px){
  .container {
width: 970px;
  }
}
@media (min-width:1200px){
  .container {
width: 1170px;
  }
}
@media (max-width:767px){
  .navbar-form .form-group {
      display: inline-block;
      float: left;
      padding: 0 0 0 4%;
      width: 68%;
  }
  .navbar-form .form-control {
      box-shadow: none;
      border: 0;
      margin: 0;
      width: 100%;
  }
  .dropdown-menu .navbar-form .btn-default,
  .navbar-form .btn-default {
      margin: 0 0 0 10px;
  }    
  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
      color: #fff;
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
      border: 0;
      margin: 0;
      width: 100%;
  }

}
@media (max-width:600px){
  .container .jumbotron {
      margin: 0;
      text-align: center;
  }
  .container .jumbotron img.intro-image {
      float: none;
      margin: 0;
  }
  .container .jumbotron h2, .container-fluid .jumbotron h2 {
      clear: both;
      width: 100%;
  }

  .table {
      display: block;
      width: 100%;
  }
  .table thead {
      display: none;
  }
  .table tbody,
  .table tr,
  .table td {
      display: block;
  }
  .table tr.success {
      border-bottom: 1px solid #74CD56;
  }
  .table tr.danger {
      border-bottom: 1px solid #CB1111;
  }
}
@media (max-width:481px){
  .container .jumbotron img.intro-image {
      margin: 0 0 20px;
  }


}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
  font-family: "Cooper-Std-Black";
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "Cooper-Std-Black";
  color: #ee5a42;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.new-song-image {
  height:auto; 
  width:auto; 
  max-height:30px;
}


.arrow-up-image {
  height:auto; 
  width:auto; 
  max-height:22px;
}